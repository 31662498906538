{
  "name": "sostereo-client",
  "version": "13.0.9",
  "private": true,
  "dependencies": {
    "@amcharts/amcharts4": "^4.10.34",
    "@amcharts/amcharts4-geodata": "^4.1.26",
    "@angular-devkit/build-angular": "^17.1.1",
    "@angular-devkit/core": "^17.1.1",
    "@angular-devkit/schematics": "^17.1.1",
    "@angular/animations": "^17.1.1",
    "@angular/cdk": "^17.1.1",
    "@angular/cdk-experimental": "^17.1.1",
    "@angular/common": "^17.1.1",
    "@angular/compiler": "^17.1.1",
    "@angular/compiler-cli": "^17.1.1",
    "@angular/core": "^17.1.1",
    "@angular/forms": "^17.1.1",
    "@angular/material": "^17.1.1",
    "@angular/platform-browser": "^17.1.1",
    "@angular/platform-browser-dynamic": "^17.1.1",
    "@angular/platform-server": "^17.1.1",
    "@angular/router": "^17.1.1",
    "@angular/service-worker": "^17.1.1",
    "@angular/ssr": "^17.1.3",
    "@cubejs-client/core": "^0.32.0",
    "@cubejs-client/ngx": "^0.32.0",
    "@mongodb-js/charts-embed-dom": "^3.0.0",
    "@schematics/angular": "^17.0.0",
    "@sentry/browser": "^7.41.0",
    "@sentry/tracing": "^7.41.0",
    "@tweenjs/tween.js": "^18.6.4",
    "@types/d3": "^7.4.3",
    "@types/d3-cloud": "^1.2.9",
    "@types/lodash-es": "^4.17.12",
    "@types/pubnub": "^7.4.2",
    "@types/three": "^0.165.0",
    "@vimeo/player": "^2.20.1",
    "@zip.js/zip.js": "^2.6.75",
    "angular": "^1.8.3",
    "angular-file": "^3.6.0",
    "angular-slick-carousel": "3.1.7",
    "angular-ui-router": "^1.0.30",
    "angular2-multiselect-dropdown-ivy": "^1.0.4",
    "bootstrap": "^3.3.7",
    "chart.js": "^4.2.1",
    "chartjs-adapter-date-fns": "^3.0.0",
    "chartjs-plugin-datalabels": "^2.2.0",
    "core-js": "^3.29.0",
    "d3": "^7.9.0",
    "d3-cloud": "^1.2.7",
    "domino-ext": "^2.1.4",
    "enter-view": "^2.0.1",
    "express": "^4.18.2",
    "intersection-observer": "^0.12.2",
    "jquery": "^3.6.3",
    "localstorage-polyfill": "^1.0.1",
    "lodash-es": "^4.17.21",
    "lottie-web": "^5.12.2",
    "masonry-layout": "^4.2.2",
    "material-icons": "^1.13.2",
    "moment": "^2.29.4",
    "ng-drag-drop": "^5.0.0",
    "ng-in-viewport": "^15.0.1",
    "ng-recaptcha": "^12.0.2",
    "ng2-charts": "^4.1.1",
    "ngx-auth": "^5.4.0",
    "ngx-bootstrap": "^11.0.2",
    "ngx-build-plus": "^17.0.0",
    "ngx-clipboard": "^15.1.0",
    "ngx-device-detector": "^6.0.2",
    "ngx-editor": "^17.5.4",
    "ngx-infinite-scroll": "^16.0.0",
    "ngx-json-viewer": "^3.2.1",
    "ngx-lottie": "^11.0.0",
    "ngx-masonry": "^14.0.1",
    "ngx-pagination": "^6.0.3",
    "ngx-select-ex": "^8.0.1",
    "ngx-skeleton-loader": "^7.0.0",
    "ngx-slick-carousel": "^17.0.0",
    "ngx-slider-v2": "^16.0.2",
    "ngx-socket-io": "^4.5.1",
    "ngx-toastr": "^17.0.2",
    "ngx-ui-switch": "^14.0.3",
    "pubnub": "^7.6.1",
    "pug-cli": "^1.0.0-alpha6",
    "rxjs": "^7.8.0",
    "sessionstorage-polyfill": "^1.0.1",
    "slick-carousel": "^1.8.1",
    "ssr-window": "^4.0.2",
    "stream": "0.0.2",
    "tc-angular-chartjs": "~2.1.4",
    "three": "^0.164.1",
    "tslib": "^2.5.0",
    "wavesurfer.js": "^7.6.3",
    "webpack-bundle-analyzer": "^4.8.0",
    "youtube-player": "^5.6.0",
    "zone.js": "^0.14.2"
  },
  "devDependencies": {
    "@angular-builders/custom-webpack": "^17.0.0",
    "@angular-builders/dev-server": "^7.3.1",
    "@angular-eslint/builder": "17.2.1",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/schematics": "^17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "^17.1.3",
    "@angular/language-service": "^17.1.1",
    "@cypress/code-coverage": "^3.12.19",
    "@cypress/schematic": "^2.5.1",
    "@istanbuljs/nyc-config-typescript": "^1.0.2",
    "@jsdevtools/coverage-istanbul-loader": "^3.0.5",
    "@types/cypress": "^1.1.3",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~4.3.1",
    "@types/jasminewd2": "^2.0.10",
    "@types/node": "^18.18.0",
    "@typescript-eslint/eslint-plugin": "^6.10.0",
    "@typescript-eslint/parser": "^6.10.0",
    "autoprefixer": "^10.4.20",
    "aws-cdk-lib": "^2.124.0",
    "browser-sync": "^3.0.0",
    "codelyzer": "^6.0.2",
    "constructs": "^10.3.0",
    "cypress": "^12.17.4",
    "eslint": "^8.53.0",
    "eslint-config-airbnb-typescript": "^17.0.0",
    "eslint-config-prettier": "^8.7.0",
    "eslint-plugin-import": "^2.27.5",
    "eslint-plugin-jasmine": "^4.1.3",
    "eslint-plugin-prettier": "^4.2.1",
    "html-webpack-plugin": "^5.5.0",
    "husky": "^8.0.3",
    "istanbul-lib-coverage": "^3.2.2",
    "jasmine-core": "~4.5.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.1",
    "karma-chrome-launcher": "~3.1.1",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "karma-mocha-reporter": "^2.2.5",
    "karma-remap-istanbul": "^0.6.0",
    "lint-staged": "^13.1.2",
    "ng-cache-loader": "0.0.26",
    "ng-packagr": "^17.1.1",
    "nyc": "^15.1.0",
    "postcss": "^8.4.47",
    "prettier": "^2.8.4",
    "required-loader": "^1.3.16",
    "tailwindcss": "^3.4.14",
    "ts-node": "~10.9.1",
    "tslint": "~6.1.3",
    "typescript": "~5.2.2",
    "webpack": "^5.90.0"
  },
  "browser": {
    "crypto": false
  },
  "scripts": {
    "push-qa-sostereo": "ng build sostereo-services && ng build --configuration=qa-sostereo --output-hashing=all && aws s3 rm s3://qa.sostereo.com --recursive && aws s3 cp dist/sostereo-client/ s3://qa.sostereo.com/ --recursive --acl public-read --metadata-directive REPLACE --cache-control max-age=0 --region us-west-2",
    "push-qa-stereospaces": "ng build sostereo-services && ng build --configuration=qa-stereospaces && aws s3 rm s3://qa.stereospaces.com --recursive && aws s3 cp dist/sostereo-client/ s3://qa.stereospaces.com/ --recursive --acl public-read && aws cloudfront create-invalidation --distribution-id ETFRCNV7MSMEN --paths /index.html",
    "build-production-sostereo": "ng build --configuration=production-sostereo --output-hashing=all && cp src/robots-prod.txt dist/sostereo-client/robots.txt",
    "push-production-sostereo": "ng build sostereo-services && npm run build-production-sostereo && aws s3 rm s3://www.sostereo.com --recursive && aws s3 cp dist/sostereo-client/ s3://www.sostereo.com/ --recursive --acl public-read",
    "build-production-stereospaces": "ng build --configuration=production-stereospaces",
    "push-production-stereospaces": "ng build sostereo-services && npm run build-production-stereospaces && aws s3 rm s3://stereospaces.com --recursive && aws s3 cp dist/sostereo-client/ s3://stereospaces.com/ --recursive --acl public-read",
    "build:stats": "ng build --stats-json",
    "analyzer": "ng build --configuration=production-sostereo --stats-json && webpack-bundle-analyzer dist/sostereo-client/stats.json",
    "ng": "ng",
    "build": "ng build",
    "test": "ng test",
    "eject": "ng eject",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "build:ssr:qa": "NODE_OPTIONS=--max-old-space-size=8000 node ./node_modules/@angular/cli/bin/ng run sostereo-client:server:qa-sostereo",
    "build:ssr:qa:server": "ng build --configuration=qa-sostereo && ng run sostereo-client:server:qa-sostereo",
    "build:ssr:production": "ng build --configuration=production-sostereo && ng run sostereo-client:server:production-sostereo",
    "dev": "sst dev",
    "deploy": "sst deploy",
    "serve:coverage": "ng run sostereo-client:serve-coverage",
    "e2e:ci": "ng run sostereo-client:e2e-ci",
    "e2e:coverage": "npx nyc report --reporter=lcov --reporter=text-summary",
    "serve:ssr:sostereo-client": "node dist/sostereo-client/server/server.mjs"
  },
  "husky": {
    "hooks": {
      "pre-commit": "lint-staged --relative"
    }
  },
  "lint-staged": {
    "*.{js,ts,html}": [
      "eslint --fix"
    ]
  },
  "buildNumber": "2647"
}
